import Vue from 'vue';

interface FormField {
    label: string;
    value: any;
}

interface Form {
    username: FormField;
    apartment: FormField;
    dong: FormField;
    ho: FormField;
    phone: FormField;
    email: FormField;
    content: FormField;
}

export default Vue.extend({
    data () {
        return {
            tabs_labs: null,
            check: ['white'],
            dialog: false,
            dialog_video: true,
            form: {
                username: {
                    label: "고객명",
                    value: null
                },
                apartment: {
                    label: "아파트명",
                    value: null
                },
                dong: {
                    label: "동",
                    value: null
                },
                ho: {
                    label: "호수",
                    value: null
                },
                phone: {
                    label: "전화번호",
                    value: null
                },
                email: {
                    label: "이메일",
                    value: null
                },
                content: {
                    label: "문의내용",
                    value: null
                }
            } as Form
        };
    },
    methods:
    {
        HANDLE_SEND_SLACK(key: any) {
            if (!this.isPrivacyAgreed()) return;
            if (!this.areRequiredFieldsFilled()) return;

            const content = this.createContentMessage(key);
            this.sendSlackMessage(content);
        },

        isPrivacyAgreed(): boolean {
            if (this.check.length < 2) {
                this.$toasted.show("개인정보 수집이용에 동의해주세요.");
                return false;
            }
            return true;
        },

        areRequiredFieldsFilled(): boolean {
            const requiredFields: (keyof Form)[] = ['username', 'apartment', 'dong', 'ho', 'phone'];
            for (const key of requiredFields) {
                if (this.form[key].value === null) {
                    this.$toasted.show(`${this.form[key].label}을(를) 작성해 주세요.`);
                    return false;
                }
            }
            return true;
        },

        createContentMessage(type: string): string {
            const contentArr = [`📢 홈페이지-${type} 문의`];

            const changeKey = {
                username: "성함",
                apartment: "직급",
                dong: "회사명",
                ho: "현장명",
                phone: "전화번호",
                email: "이메일",
                content: "문의내용"
            };

            Object.keys(this.form).forEach(key => {
                const typedKey = key as keyof Form;
                const value = this.form[typedKey].value;
                const label = (type !== "입주민") ? changeKey[typedKey] : this.form[typedKey].label;

                contentArr.push(this._.sprintf("%s: %s", [label, value]));
            });

            return contentArr.join('\n');
        },

        resetFormValues() {
            Object.keys(this.form).forEach(key => {
                const typedKey = key as keyof Form;
                this.form[typedKey].value = null;
            });
            this.check = ['white'];
        },

        sendSlackMessage(content: string) {
            const payload = JSON.stringify({content});
            this.$aws.lambda.invoke(
                process.env.VUE_APP_AWS_LAMBDA_SLACK_SEND,
                "RequestResponse",
                payload
            ).then(res => {
                this.resetFormValues();
                this.dialog = true;
            });
        }
    },
    computed:
    {
        usePrivacy() {
            return process.env.VUE_APP_PRIVACY;
        }
    }
});
